// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg
            width="100"
            height="50"
            version="1.1"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 1222 587"
        >
            <g>
                <g id="_1421888631968">
                    <path
                        fill="#2E3059"
                        fill-rule="nonzero"
                        d="M175 303c6,-31 21,-60 44,-84 24,-23 53,-39 84,-44 -2,-26 -14,-52 -34,-72 -5,-5 -9,-9 -15,-13 -38,12 -72,34 -101,62 -29,30 -50,64 -63,102 4,6 8,10 13,15 20,20 46,32 72,34l0 0zm393 -68c-13,-31 -31,-59 -55,-83 -47,-46 -109,-73 -175,-74 21,30 33,64 34,99 8,2 15,4 22,7 20,8 37,20 52,35 31,30 47,70 47,113 0,43 -16,83 -47,114 -30,30 -70,47 -113,47 -43,0 -83,-17 -114,-47 -20,-21 -35,-47 -41,-74 -35,-2 -70,-13 -100,-34 2,66 28,127 75,174 48,48 112,75 180,75 68,0 132,-27 180,-75 48,-48 74,-112 74,-180 0,-33 -6,-67 -19,-97l0 0z"
                    />
                    <path
                        fill="#F9B101"
                        fill-rule="nonzero"
                        d="M186 69c15,0 30,2 45,8 8,4 16,8 23,13 25,-8 52,-12 79,-12 1,0 3,0 5,0 -6,-8 -12,-16 -20,-23 -72,-73 -191,-73 -263,0 -36,35 -55,82 -55,131 0,50 19,97 55,132 7,7 15,14 23,20 0,-2 0,-4 0,-6 0,-27 4,-53 12,-78 -14,-19 -21,-43 -21,-68 0,-31 12,-61 34,-83 23,-23 53,-34 83,-34l0 0zm186 108c-12,-3 -26,-5 -39,-5 -10,0 -20,1 -30,3 4,33 -8,68 -34,94 -25,26 -60,37 -94,34 -4,23 -3,47 3,69 2,0 5,0 8,0 48,0 96,-18 132,-54 36,-36 55,-84 55,-132 0,-3 0,-6 -1,-9l0 0z"
                    />
                </g>
                <g id="_1421888631968_0">
                    <path
                        fill="#2E3059"
                        fill-rule="nonzero"
                        d="M175 303c6,-31 21,-60 44,-84 24,-23 53,-39 84,-44 -2,-26 -14,-52 -34,-72 -5,-5 -9,-9 -15,-13 -38,12 -72,34 -101,62 -29,30 -50,64 -63,102 4,6 8,10 13,15 20,20 46,32 72,34l0 0zm393 -68c-13,-31 -31,-59 -55,-83 -47,-46 -109,-73 -175,-74 21,30 33,64 34,99 8,2 15,4 22,7 20,8 37,20 52,35 31,30 47,70 47,113 0,43 -16,83 -47,114 -30,30 -70,47 -113,47 -43,0 -83,-17 -114,-47 -20,-21 -35,-47 -41,-74 -35,-2 -70,-13 -100,-34 2,66 28,127 75,174 48,48 112,75 180,75 68,0 132,-27 180,-75 48,-48 74,-112 74,-180 0,-33 -6,-67 -19,-97l0 0z"
                    />
                    <path
                        fill="#F9B101"
                        fill-rule="nonzero"
                        d="M186 69c15,0 30,2 45,8 8,4 16,8 23,13 25,-8 52,-12 79,-12 1,0 3,0 5,0 -6,-8 -12,-16 -20,-23 -72,-73 -191,-73 -263,0 -36,35 -55,82 -55,131 0,50 19,97 55,132 7,7 15,14 23,20 0,-2 0,-4 0,-6 0,-27 4,-53 12,-78 -14,-19 -21,-43 -21,-68 0,-31 12,-61 34,-83 23,-23 53,-34 83,-34l0 0zm186 108c-12,-3 -26,-5 -39,-5 -10,0 -20,1 -30,3 4,33 -8,68 -34,94 -25,26 -60,37 -94,34 -4,23 -3,47 3,69 2,0 5,0 8,0 48,0 96,-18 132,-54 36,-36 55,-84 55,-132 0,-3 0,-6 -1,-9l0 0z"
                    />
                </g>
                <g id="_1421888631968_1">
                    <path
                        fill="#2E3059"
                        fill-rule="nonzero"
                        d="M175 303c6,-31 21,-60 44,-84 24,-23 53,-39 84,-44 -2,-26 -14,-52 -34,-72 -5,-5 -9,-9 -15,-13 -38,12 -72,34 -101,62 -29,30 -50,64 -63,102 4,6 8,10 13,15 20,20 46,32 72,34l0 0zm393 -68c-13,-31 -31,-59 -55,-83 -47,-46 -109,-73 -175,-74 21,30 33,64 34,99 8,2 15,4 22,7 20,8 37,20 52,35 31,30 47,70 47,113 0,43 -16,83 -47,114 -30,30 -70,47 -113,47 -43,0 -83,-17 -114,-47 -20,-21 -35,-47 -41,-74 -35,-2 -70,-13 -100,-34 2,66 28,127 75,174 48,48 112,75 180,75 68,0 132,-27 180,-75 48,-48 74,-112 74,-180 0,-33 -6,-67 -19,-97l0 0z"
                    />
                    <path
                        fill="#F9B101"
                        fill-rule="nonzero"
                        d="M186 69c15,0 30,2 45,8 8,4 16,8 23,13 25,-8 52,-12 79,-12 1,0 3,0 5,0 -6,-8 -12,-16 -20,-23 -72,-73 -191,-73 -263,0 -36,35 -55,82 -55,131 0,50 19,97 55,132 7,7 15,14 23,20 0,-2 0,-4 0,-6 0,-27 4,-53 12,-78 -14,-19 -21,-43 -21,-68 0,-31 12,-61 34,-83 23,-23 53,-34 83,-34l0 0zm186 108c-12,-3 -26,-5 -39,-5 -10,0 -20,1 -30,3 4,33 -8,68 -34,94 -25,26 -60,37 -94,34 -4,23 -3,47 3,69 2,0 5,0 8,0 48,0 96,-18 132,-54 36,-36 55,-84 55,-132 0,-3 0,-6 -1,-9l0 0z"
                    />
                </g>
                <g id="_1421888631968_2">
                    <path
                        fill="#2E3059"
                        fill-rule="nonzero"
                        d="M175 303c6,-31 21,-60 44,-84 24,-23 53,-39 84,-44 -2,-26 -14,-52 -34,-72 -5,-5 -9,-9 -15,-13 -38,12 -72,34 -101,62 -29,30 -50,64 -63,102 4,6 8,10 13,15 20,20 46,32 72,34l0 0zm393 -68c-13,-31 -31,-59 -55,-83 -47,-46 -109,-73 -175,-74 21,30 33,64 34,99 8,2 15,4 22,7 20,8 37,20 52,35 31,30 47,70 47,113 0,43 -16,83 -47,114 -30,30 -70,47 -113,47 -43,0 -83,-17 -114,-47 -20,-21 -35,-47 -41,-74 -35,-2 -70,-13 -100,-34 2,66 28,127 75,174 48,48 112,75 180,75 68,0 132,-27 180,-75 48,-48 74,-112 74,-180 0,-33 -6,-67 -19,-97l0 0z"
                    />
                    <path
                        fill="#F9B101"
                        fill-rule="nonzero"
                        d="M186 69c15,0 30,2 45,8 8,4 16,8 23,13 25,-8 52,-12 79,-12 1,0 3,0 5,0 -6,-8 -12,-16 -20,-23 -72,-73 -191,-73 -263,0 -36,35 -55,82 -55,131 0,50 19,97 55,132 7,7 15,14 23,20 0,-2 0,-4 0,-6 0,-27 4,-53 12,-78 -14,-19 -21,-43 -21,-68 0,-31 12,-61 34,-83 23,-23 53,-34 83,-34l0 0zm186 108c-12,-3 -26,-5 -39,-5 -10,0 -20,1 -30,3 4,33 -8,68 -34,94 -25,26 -60,37 -94,34 -4,23 -3,47 3,69 2,0 5,0 8,0 48,0 96,-18 132,-54 36,-36 55,-84 55,-132 0,-3 0,-6 -1,-9l0 0z"
                    />
                </g>
                <polygon fill="black" fill-rule="nonzero" points="872,180 872,482 783,482 783,180 " />
                <path
                    id="1"
                    fill="black"
                    fill-rule="nonzero"
                    d="M1023 267l0 215 -90 0 0 -302 117 0c51,0 91,12 120,35 35,28 52,66 52,114 0,45 -15,83 -44,112 -30,28 -68,43 -114,43 -6,0 -15,0 -27,-1l0 -89 14 0c53,0 79,-22 79,-65 0,-41 -26,-62 -77,-62l-30 0z"
                />
            </g>
        </svg>
    );
};

export default Logo;
